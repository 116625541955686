import React from "react"
import { Link } from "gatsby"
import { FaEnvelope, FaPaperPlane } from "react-icons/fa"

import SEO from "../components/seo"

import BannerImg from "../assets/img/youMedico/bannerproviders.png"
import EllipseIcon from "../assets/img/youMedico/for-providers/ellipse.svg"
import Rectangle from "../assets/img/youMedico/rectangle.svg"
import TrackImg from "../assets/img/youMedico/for-providers/track.png"
import Advanced2Img from "../assets/img/youMedico/for-providers/advanced2.png"
import Specialized1Img from "../assets/img/youMedico/for-providers/specialized1.png"
import Video1Img from "../assets/img/youMedico/for-providers/video1.png"
import { navigate } from "gatsby"
import appendScript from '../utils/appendScript';

import "../scss/providers.scss"

const ForProvidersPage = () => {

  appendScript("https://ma.youpal.se/form/generate.js?id=31", "contact-us-mautic-internal-form");

  return (
    <div className="providersPage">
      <SEO
        title="For Providers"
        keywords={[`youpal`, `youpal group`, `for providers`]}
      />
      <div className="youMedicoMain">
        <section className="container mb-5 internalSection">
          <div className="sectionSeparation row TwoCol forProvidersBackgroundImage">
            <div className="col d-lg-none">
              <img src={BannerImg} className="sideImg" />
            </div>
            <div className="col">
              <div className="col sideContent">
                <h2 className="text-left mainTitle">
                  Go Remote And Your Patients Will Multiply
                </h2>
                <p>
                Youmedico will keep your high-risk patients away from the hospital and provide you with the means to monitor their health and treat them accordingly.
                </p>
                <div className="buttonArea">
                  <button
                    type="button"
                    className="px-5 text-center btn btn-primary"
                    onClick={() => navigate("/contact-us")}
                  >
                    <span className="mr-0">Request a demo</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="col"></div>
          </div>
        </section>

        <section className="container">
          <div className="col sectionSeparation">
            <img src={Rectangle} />
            <h3 className="mb-5 internalNarrowCol">
            How Can You Benefit From Youmedico?
            </h3>
            <p className="mt-5">
            Our solutions optimise the entire process of patient care from appointment, diagnosis to treatment.
            </p>
            <p className="mt-5">
            Doctors can easily track and monitor patients’ health status using our software.
            </p>
          </div>
        </section>

        <section className="container internalSection">
          <div className="mb-5 row TwoCol sectionSeparation">
            <div className="offset-lg-1 col">
              <img className="sideImg" src={TrackImg} />
            </div>
            <div className="col">
              <div className="col sideContent">
                <h4 className="text-left">Track & Monitor</h4>
                <p>
                Our software solutions ensure patients are able to understand their treatment plan effectively.
                </p>
              </div>
            </div>
          </div>
          <div className="mb-5 row TwoCol sectionSeparation sectionSeparationAlternate">
            <div className="col">
              <div className="col sideContent">
                <h4 className="text-left">Advanced Segmentation</h4>
                <p>
                Our in-built algorithm provides you with valuable analytics optics and information. This helps doctors minimise risks and maximise treatment efficiency.
                </p>
              </div>
            </div>
            <div className="col">
              <img className="sideImg" src={Advanced2Img} />
            </div>
          </div>
          <div className="row TwoCol sectionSeparation">
            <div className="col-lg-4">
              <img className="sideImg" src={Specialized1Img} />
            </div>
            <div className="offset-lg-1 col">
              <div className="col sideContent">
                <h4 className="text-left">Specialized Care</h4>
                <p>
                We have a pre-existing protocol for multiple health issues which can be easily adapted according to specific conditions. They include:
                </p>
                <div className="row collaborationList">
                  <div className="col">
                    <div className="row">
                      <div className="col">
                        <img src={EllipseIcon} />
                        Hypertension
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <img src={EllipseIcon} />
                        Diabetes
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <img src={EllipseIcon} />
                        Cancer
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <img src={EllipseIcon} />
                        Pain management
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <img src={EllipseIcon} />
                        Obesity
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <img src={EllipseIcon} />
                        Heart diseases
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <img src={EllipseIcon} />
                        Asthma, etc.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-5 row TwoCol sectionSeparation">
            <div className="col">
              <div className="col sideContent">
                <h4 className="text-center">Cloud-based Database</h4>
                <p className="internalNarrowCol text-center">
                Youmedico’s cloud database can gather patient information which can be accumulated and examined to improve patients’ overall health and power patient care programs with preoperative assessments, post-discharge monitoring, session alerts and related documentation.
                </p>
              </div>
            </div>
          </div>
          <div className="row TwoCol">
            <div className="col-lg-2 col">
              <img className="sideImg" src={Video1Img} />
            </div>
            <div className="offset-lg-2 col">
              <div className="col sideContent">
                <h4 className="text-left">Video Sessions</h4>
                <p>
                We offer high-quality video call options for patients who prefer speaking to their doctors face-to-face.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="container">
          <div className="row contactBox">
            <div className="col">
              <h2>Request a demo</h2>
              <div className="sendLinkContainer">
                  <div className="formDiv">
                      <FaEnvelope size={25} />
                      {/* Mautic form */}
                      <div id='contact-us-mautic-internal-form'/>
                  </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default ForProvidersPage
